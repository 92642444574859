import React from "react";
import PropTypes from "prop-types";
import "../../dist/css/responsive.css";
import { FaArrowDown } from "react-icons/fa/";

const Hero = props => {
  const { scrollToContent, backgrounds, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <div className="heroTextBox">
          <h1 className="authorName">Caleb Woods</h1>
          <h3 className="heroAuthor">Author</h3>
          <h3 className="heroWriter">Writer</h3>
          <h3 className="heroPoet">Poet</h3>
        </div>

        <button onClick={scrollToContent} aria-label="scroll" className="theme-btn btn-style-four">
          BIO <FaArrowDown />
        </button>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .heroTextBox {
          width: 40%;
          margin-left: -40%;
          margin-top: -5%;
          margin-bottom: 10%;
          color: black !important;
        }

        .authorName {
          margin-bottom: 2%;
        }

        .heroAuthor {
          margin-left: 8%;
        }

        .heroWriter {
          margin-left: 25%;
        }

        .heroPoet {
          margin-left: 42%;
        }

        .theme-btn {
          display: inline-block;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }

        .centered {
          text-align: center;
        }

        .btn-style-four {
          position: relative;
          padding: 8px 35px;
          line-height: 24px;
          background: #46c1d7;
          text-transform: uppercase;
          color: #ffffff;
          border: 2px solid #46c1d7;
          font-size: 15px;
          font-weight: 700;
          border-radius: 3px;
        }

        .btn-style-four:hover {
          background: none;
          color: #46c1d7;
        }

        .theme_color {
          color: #c18f59;
        }

        .light-font {
          font-weight: 300;
        }

        .regular-font {
          font-weight: 400;
        }

        .semibold-font {
          font-weight: 600;
        }

        .medium-font {
          font-weight: 500;
        }

        .bold-font {
          font-weight: 700;
        }
        .hero {
          align-items: center;
          background: ${theme.hero.background};
          background-image: url(${backgrounds.mobile});
          background-size: cover;
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          min-height: 100vh;
          height: 100px;
          padding: ${theme.space.inset.l};
          padding-top: ${theme.header.height.homepage};
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.tablet});
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
