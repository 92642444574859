import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import Carousel from "nuka-carousel";

const Testimonials = () => {
  return (
    <React.Fragment>
      <section className="testimonials-section-two" id="scroll-section-five">
        <div className="auto-container">
          <div className="testimonial-carousel-two">
            <Carousel
              autoplay={true}
              autoplayInterval={3000}
              withoutControls={true}
              wrapAround={true}
              length={10}
            >
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “This is a must-read for anyone affected by mental illness. Caleb’s raw poetry
                      shows the deep struggles of mental illness and being LGBTQ. Still, Caleb
                      carves out hope with his uplifting poems about love and moving on. If you’re
                      LGBTQ, a Mama Bear, or you are affected by mental illness, please read this
                      book. It is clear Caleb bared his soul and wrote every word straight from the
                      heart.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Susan Cottrell</h4>
                  </div>
                </div>
              </div>

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “Wondering when a 2nd volume is coming… Having followed Caleb’s political and
                      human rights commentary online for years, I couldn’t wait to get my hands on
                      this. I’m not good at reviews of any kind, but I enjoyed this immensely. I
                      think if you are a fan of Plath or Duras, you should check it out. Some
                      favorites: Left out in the cold, As long as we live, Blip, A formation of
                      Atoms.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Lindsey</h4>
                  </div>
                </div>
              </div>

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “Having struggled with depression myself, I found this book to be deeply
                      inspiring and relatable. The prose is sharp, illustrating the pain that mental
                      anguish inflicts. This is an important book, facing the society of today. I
                      believe that the vulnerability of the author to submit this work to a
                      population that needs it is exactly what we need for any real connection and
                      healing to take place.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Teresa Roberts</h4>
                  </div>
                </div>
              </div>

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “The journey this author has taken is expressed starkly, with emotions naked -
                      hiding nothing. The courage it takes to share these intimate thoughts and
                      feelings is enormous. I highly recommend this book for anyone who has ever
                      struggled with mental or emotional health issues. Or for those who love
                      someone who is lost in their own mental/emotional hell. My first thought when
                      I began reading this amazing collection of poetry was “it’s not just me!” I am
                      a fan for life. It isn’t an easy story to hear, but it is oh so very
                      necessary. I highly recommend this book.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Debby</h4>
                  </div>
                </div>
              </div>
    
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “As a high school English teacher, I love finding poetry that can and will speak to 
                      my students. This book is totally relatable to so many young people today and the 
                      struggles they face. Caleb takes the reader through the trials of mental illness 
                      with an honest and transparent voice. Both heart-breaking and poignant, 
                      this book speaks for those who struggle with depression but don’t know how to 
                      articulate it. This will become a staple for readers in my classroom.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- J. Holland-Griggs</h4>
                  </div>
                </div>
              </div>    
    
              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “Caleb speaks the truth and he touches my very soul. I deal with Depression on a 
                      daily basis personally and professionally. As a former USAF Chaplain, his words 
                      speak to my heart and to the hearts of those whom I have walked along side of and 
                      to those who have lost the battle agains the darkness. Thank you Caleb, 
                      for speaking to my heart of darkness, light, and hope. This book of verse is 
                      so much more than a book of poetry! Everyone needs to read this and soak it in. 
                      You never know when you will meet someone in need of love and hope. Caleb’s words 
                      will help you to do that.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Michael Moore</h4>
                  </div>
                </div>
              </div>        

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “I'm not much of a poetry reader—of all the kinds of literature I love, 
                      I've always found poetry to be largely impenetrable—except when the poetry 
                      involves something that interests me. And because of various family members 
                      who suffer from mental illnesses, I picked up this book, and WOW, am I ever 
                      glad I did. Caleb Woods has written a searingly honest book about growing up 
                      gay in the South in a very Christian home, while describing the depression and 
                      anxiety he dealt with in such a painfully true way I felt like I was in his head 
                      for a lot of the book. This isn't a depressing read—he offers so much hope 
                      and love to those who stick by him throughout his life—but instead is a book that says, 
                      you want to know what it feels like to want to kill yourself every day, regardless of 
                      the shining sun and the singing birds? Here, I'll show you. This is a book that should 
                      be given to anyone who's been suicidal and wants to know they're not alone, but more 
                      importantly, to those people who have loved ones who have been through hell and want 
                      to know how to help. Woods is an incredible talent, and his writing was astounding. 
                      I began reading some of the poems one night before bed, and then picked it up 
                      one morning while I was waiting for my tea to steep. An hour later the tea was cold, 
                      I hadn't moved from my spot, and I felt like I'd run a marathon. That is the mark of 
                      truly brilliant writing.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Nikki Stafford</h4>
                  </div>
                </div>
              </div>            

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “Woods has written truly something special in that he had the courage to bare his own 
                      struggles so that his readers might connect with him and not feel alone. His prose 
                      teeters on the edge of hope, detailing the thin line between loss and triumph. 
                      Woods lets us the know that the mundane things of everyday can seem harder than 
                      they're supposed to, but that he's in it, with us, making it easier through his 
                      thoughtful words. 100% recommend this book for ANYONE.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Ryan</h4>
                  </div>
                </div>
              </div>     

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “I got this today and spent the afternoon reading every poem and then spent another hour 
                      or so processing. While reading, the same verse from the Tao kept coming to me, “Knowing 
                      others is intelligence; knowing yourself is wisdom. Mastering others is strength; mastering 
                      yourself is power.” This collection of poems is from a brilliant mind. It is raw and 
                      unapologetic. Caleb gives a voice to the dark consciousness that many who suffer from depression, 
                      anxiety, etc. only wish could be said. In the poems we see pain and disparity, fear and doubt. 
                      But like the petals of the padma opening we see a glimpse of healing and contentment; 
                      realization and being. There is no “happy ending” to this, nor does he offer any anecdote 
                      of wisdom. The brilliance in this work is the author himself. These poems represent a struggle 
                      with darkness and it ends with him. He is still with us and has much more to offer. Thank you, 
                      Caleb for this gift you’ve given to all of us. Now, what’s next?”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Amazon Customer</h4>
                  </div>
                </div>
              </div>     

              <div className="testimonial-block">
                <div className="inner-box">
                  <div className="quote-icon">
                    <FaQuoteLeft />
                  </div>
                  <div className="content-box">
                    <div className="text">
                      “I experienced the inner thoughts of a person suffering with mental illness and depression 
                      while reading this book. The author took me on a journey that nobody would want to experience 
                      in real life, but gave moments of hope and a happiness that made this reader believe that 
                      people can come back from the depths of despair to show a feeling of self-worth and self-love.”
                    </div>
                  </div>
                  <div className="author-info">
                    {/* <div className="author-thumb img-circle">
                      <img className="img-circle" src="http://www.placecorgi.com/77/77" alt="" />
                    </div> */}
                    <h4>- Erika L.</h4>
                  </div>
                </div>
              </div>    
    
            </Carousel>
          </div>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        body {
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          color: #777777;
          line-height: 1.8em;
          font-weight: 400;
          background: #ffffff;
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing: antialiased;
        }

        a {
          text-decoration: none;
          cursor: pointer;
          color: #c18f59;
        }

        a:hover,
        a:focus,
        a:visited {
          text-decoration: none;
          outline: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          position: relative;
          font-family: "EB Garamond", serif;
          font-weight: normal;
          margin: 0px;
          background: none;
          line-height: 1.4em;
        }

        input,
        button,
        select,
        textarea {
          font-family: "Poppins", sans-serif;
        }

        p {
          position: relative;
          line-height: 1.8em;
        }

        .strike-through {
          text-decoration: line-through;
        }

        .auto-container {
          position: static;
          max-width: 1200px;
          padding: 0px 15px;
          margin: 0 auto;
        }

        .medium-container {
          max-width: 850px;
        }

        .page-wrapper {
          position: relative;
          margin: 0 auto;
          width: 100%;
          min-width: 300px;
        }

        .page-container {
          position: relative;
          width: 100%;
          border: 12px solid transparent;
          background: #0d0f11;
        }

        ul,
        li {
          list-style: none;
          padding: 0px;
          margin: 0px;
        }

        .theme-btn {
          display: inline-block;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }

        .centered {
          text-align: center;
        }

        .btn-style-one {
          position: relative;
          padding: 11px 40px;
          line-height: 24px;
          background: #c18f59;
          color: #ffffff;
          border: 2px solid #c18f59;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-one:hover {
          background: none;
          border-color: #c18f59;
          color: #c18f59;
        }

        .btn-style-two {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #ffffff;
          color: #222222;
          border: 2px solid #ffffff;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-two:hover {
          background: none;
          border-color: #ffffff;
          color: #ffffff;
        }

        .btn-style-three {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: none;
          text-transform: uppercase;
          color: #46c1d7;
          border: 2px solid #d0d0d0;
          font-size: 13px;
          font-weight: 600;
          border-radius: 0px;
        }

        .btn-style-three:hover {
          background: #46c1d7;
          border-color: #46c1d7;
          color: #ffffff;
        }

        .btn-style-three .fa {
          position: relative;
          top: 0px;
          padding-left: 5px;
          font-weight: 400;
        }

        .btn-style-four {
          position: relative;
          padding: 8px 35px;
          line-height: 24px;
          background: #46c1d7;
          text-transform: uppercase;
          color: #ffffff;
          border: 2px solid #46c1d7;
          font-size: 15px;
          font-weight: 700;
          border-radius: 3px;
        }

        .btn-style-four:hover {
          background: none;
          color: #46c1d7;
        }

        .btn-style-five {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #bf9456;
          border: 2px solid #bf9456;
          font-size: 14px;
          font-weight: 400;
          border-radius: 0px;
          font-family: "Roboto Slab", serif;
        }

        .btn-style-five:hover {
          background: #bf9456;
          color: #ffffff;
        }

        .btn-style-six {
          position: relative;
          padding: 11px 25px;
          line-height: 24px;
          background: #6775de;
          color: #ffffff;
          border: 2px solid #6775de;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-six:hover {
          background: none;
          color: #6775de;
        }

        .btn-style-seven {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #8ecc3b;
          color: #ffffff;
          border: 2px solid #8ecc3b;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-seven:hover {
          background: none;
          color: #8ecc3b;
        }

        .btn-style-eight {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #ffffff;
          border: 2px solid #ffffff;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-eight:hover {
          color: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-nine {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: #010101;
          color: #ffffff;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-nine:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-ten {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #010101;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Georgia", serif;
        }

        .btn-style-ten:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .theme_color {
          color: #c18f59;
        }

        .light-font {
          font-weight: 300;
        }

        .regular-font {
          font-weight: 400;
        }

        .semibold-font {
          font-weight: 600;
        }

        .medium-font {
          font-weight: 500;
        }

        .bold-font {
          font-weight: 700;
        }

        .preloader {
          position: fixed;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          z-index: 999999;
          background-color: #ffffff;
          background-position: center center;
          background-repeat: no-repeat;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        .scroll-to-top {
          position: fixed;
          bottom: 15px;
          right: 15px;
          width: 40px;
          height: 40px;
          color: #ffffff;
          font-size: 14px;
          text-transform: uppercase;
          line-height: 38px;
          text-align: center;
          z-index: 100;
          cursor: pointer;
          border-radius: 50%;
          background: #222222;
          display: none;
          -webkit-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          transition: all 300ms ease;
        }

        .scroll-to-top:hover {
          color: #ffffff;
          background: #c18f59;
        }
        .testimonials-section-two {
          position: relative;
          background: #eff0f0;
          padding: 110px 0px;
        }

        .testimonials-section-two .testimonial-block {
          position: relative;
        }

        .testimonials-section-two .testimonial-block .inner-box {
          position: relative;
          padding-top: 16px;
        }

        .testimonials-section-two .testimonial-block .quote-icon {
          position: absolute;
          right: 40px;
          top: 0px;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 24px;
          border: 4px solid #ffffff;
          background: #bbaea6;
          color: #ffffff;
          font-size: 12px;
          border-radius: 3px;
          z-index: 1;
        }

        .testimonials-section-two .testimonial-block .inner-box .content-box {
          position: relative;
          background-color: #ffffff;
          padding: 40px 30px;
          border-radius: 3px;
        }

        .testimonials-section-two .testimonial-block .inner-box .content-box .text {
          font-size: 15px;
          color: #898989;
          line-height: 1.8em;
          font-style: italic;
        }

        .testimonials-section-two .testimonial-block .inner-box .author-info {
          position: relative;
          padding-top: 18px;
          // padding-left: 95px;
          min-height: 80px;
          margin: 15px 0px 0px 30px;
        }

        .testimonials-section-two .testimonial-block .inner-box .author-info .author-thumb {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 80px;
          height: 80px;
          overflow: hidden;
        }

        .testimonials-section-two .testimonial-block .inner-box .author-info h4 {
          position: relative;
          font-size: 16px;
          color: #323232;
          font-weight: 400;
          font-family: "Roboto Slab", serif;
        }

        .testimonials-section-two .testimonial-block .inner-box .author-info .designation {
          position: relative;
          color: #888888;
          font-size: 14px;
        }

        .testimonials-section-two .owl-controls {
          display: none !important;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Testimonials;
