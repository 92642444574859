import PropTypes from "prop-types";
import React from "react";
import "../../dist/css/responsive.css";
import Item from "./Item";

const FrontBlog = props => {
  const { posts, theme } = props;

  return (
    <React.Fragment>
      <main className="main">
        <div className="eventHeader">
          <a href="/category/">Events</a>
        </div>
        <ul>
          {posts.slice(0, 4).map(post => {
            const {
              node,
              node: {
                fields: { slug }
              }
            } = post;
            return <Item key={slug} post={node} theme={theme} />;
          })}
        </ul>
      </main>

      {/* --- STYLES --- */}
      <style jsx>{`
        .eventHeader {
          font-size: 48px;
          margin-left: 46%;
          padding-top: 20px;
          margin-bottom: -30px;
        }

        .main {
          padding: 0 ${theme.space.inset.default};
        }

        ul {
          list-style: none;
          margin: 0 auto;
          padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
        }

        @above tablet {
          .main {
            padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
          }
          ul {
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @above desktop {
          ul {
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

FrontBlog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default FrontBlog;
