import React from "react";
import Image from "../Image/Image.js";
import "../../dist/css/responsive.css";

const FeaturedBook = () => {
  return (
    <React.Fragment>
      <section className="featured-book" id="scroll-section-four">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="info-column col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="inner-box">
                <h3 className="subtitle">Amazon Best Seller</h3>
                <h2 className="book-title">Harnessing Darkness</h2>
                <div className="written-by">By: Caleb Woods</div>
                <div className="price"></div>
              </div>
            </div>

            <div className="image-column col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <figure className="image-box" data-wow-delay="0ms" data-wow-duration="1500ms">
                <a
                  target="_blank"
                  href="https://www.amazon.com/Harnessing-Darkness-Expressing-Illness-Through-ebook/dp/B07TTV6S8J"
                >
                  <Image className="image" filename="Book_hdpi.jpg" height={400} width={200} />
                </a>
              </figure>
            </div>

            <div className="detail-column col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="inner-box">
                <div className="book-category">Expressing Mental Illness Through Poetry</div>
                <div className="desc-text">
                  Harnessing Darkness traces the struggle of living with mental illness. Through
                  sections aptly titled Birth, Life, Death, and Rebirth, Caleb Woods gives readers
                  the experience of what it’s like to live in the mind of someone who struggles with
                  a roller coaster of emotions on a daily basis. Caleb chooses to open the doors of
                  his mind, allowing you to see not only the dark, but the light. Caleb lets each
                  nightmare, depressed thought, and abundance of anxieties come to the surface with
                  harrowing and hopeful poems about the struggles of living with mental illness and
                  growing up gay in the Bible Belt. Experience loss, pain, heartbreak, and hope with
                  Harnessing Darkness.
                </div>
                <div id="battleBox">
                  <span className="image leftRapper tiny">
                    <Image filename="award-icon-1_hdpi.png" />
                  </span>{" "}
                  <span className="image rightRapper tiny">
                    <Image filename="award-icon-2_hdpi.png" />
                  </span>
                </div>
                <div className="link-box">
                  <a
                    href="https://www.amazon.com/Harnessing-Darkness-Expressing-Illness-Through-ebook/dp/B07TTV6S8J"
                    className="theme-btn btn-style-four top-padded"
                    target="_blank"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .top-padded {
          margin-top: 20px;
        }

        body {
          font-family: "Raleway", sans-serif;
          font-size: 14px;
          color: #777777;
          line-height: 1.8em;
          font-weight: 400;
          background: #ffffff;
          -webkit-font-smoothing: antialiased;
          -moz-font-smoothing: antialiased;
        }

        a {
          text-decoration: none;
          cursor: pointer;
          color: #c18f59;
        }

        a:hover,
        a:focus,
        a:visited {
          text-decoration: none;
          outline: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          position: relative;
          font-family: "EB Garamond", serif;
          font-weight: normal;
          margin: 0px;
          background: none;
          line-height: 1.4em;
        }

        input,
        button,
        select,
        textarea {
          font-family: "Poppins", sans-serif;
        }

        p {
          position: relative;
          line-height: 1.8em;
        }

        .strike-through {
          text-decoration: line-through;
        }

        .auto-container {
          position: static;
          max-width: 1200px;
          padding: 0px 15px;
          margin: 0 auto;
        }

        .medium-container {
          max-width: 850px;
        }

        .page-wrapper {
          position: relative;
          margin: 0 auto;
          width: 100%;
          min-width: 300px;
        }

        .page-container {
          position: relative;
          width: 100%;
          border: 12px solid transparent;
          background: #0d0f11;
        }

        ul,
        li {
          list-style: none;
          padding: 0px;
          margin: 0px;
        }

        .theme-btn {
          display: inline-block;
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
        }

        .centered {
          text-align: center;
        }

        .btn-style-one {
          position: relative;
          padding: 11px 40px;
          line-height: 24px;
          background: #c18f59;
          color: #ffffff;
          border: 2px solid #c18f59;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-one:hover {
          background: none;
          border-color: #c18f59;
          color: #c18f59;
        }

        .btn-style-two {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #ffffff;
          color: #222222;
          border: 2px solid #ffffff;
          font-size: 18px;
          font-weight: 400;
          border-radius: 3px;
          font-family: "EB Garamond", serif;
        }

        .btn-style-two:hover {
          background: none;
          border-color: #ffffff;
          color: #ffffff;
        }

        .btn-style-three {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: none;
          text-transform: uppercase;
          color: #46c1d7;
          border: 2px solid #d0d0d0;
          font-size: 13px;
          font-weight: 600;
          border-radius: 0px;
        }

        .btn-style-three:hover {
          background: #46c1d7;
          border-color: #46c1d7;
          color: #ffffff;
        }

        .btn-style-three .fa {
          position: relative;
          top: 0px;
          padding-left: 5px;
          font-weight: 400;
        }

        .btn-style-four {
          position: relative;
          padding: 8px 35px;
          line-height: 24px;
          background: #46c1d7;
          text-transform: uppercase;
          color: #ffffff;
          border: 2px solid #46c1d7;
          font-size: 15px;
          font-weight: 700;
          border-radius: 3px;
        }

        .btn-style-four:hover {
          background: none;
          color: #46c1d7;
        }

        .btn-style-five {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #bf9456;
          border: 2px solid #bf9456;
          font-size: 14px;
          font-weight: 400;
          border-radius: 0px;
          font-family: "Roboto Slab", serif;
        }

        .btn-style-five:hover {
          background: #bf9456;
          color: #ffffff;
        }

        .btn-style-six {
          position: relative;
          padding: 11px 25px;
          line-height: 24px;
          background: #6775de;
          color: #ffffff;
          border: 2px solid #6775de;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-six:hover {
          background: none;
          color: #6775de;
        }

        .btn-style-seven {
          position: relative;
          padding: 11px 35px;
          line-height: 24px;
          background: #8ecc3b;
          color: #ffffff;
          border: 2px solid #8ecc3b;
          font-size: 14px;
          font-weight: 500;
          border-radius: 3px;
          font-family: "Poppins", sans-serif;
        }

        .btn-style-seven:hover {
          background: none;
          color: #8ecc3b;
        }

        .btn-style-eight {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #ffffff;
          border: 2px solid #ffffff;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-eight:hover {
          color: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-nine {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: #010101;
          color: #ffffff;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Montserrat", sans-serif;
        }

        .btn-style-nine:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .btn-style-ten {
          position: relative;
          padding: 11px 30px;
          line-height: 24px;
          background: none;
          color: #010101;
          border: 2px solid #010101;
          border-width: 2px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          font-family: "Georgia", serif;
        }

        .btn-style-ten:hover {
          background: #ffbf00;
          border-color: #ffbf00;
        }

        .theme_color {
          color: #c18f59;
        }

        .light-font {
          font-weight: 300;
        }

        .regular-font {
          font-weight: 400;
        }

        .semibold-font {
          font-weight: 600;
        }

        .medium-font {
          font-weight: 500;
        }

        .bold-font {
          font-weight: 700;
        }

        .preloader {
          position: fixed;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          z-index: 999999;
          background-color: #ffffff;
          background-position: center center;
          background-repeat: no-repeat;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        .scroll-to-top {
          position: fixed;
          bottom: 15px;
          right: 15px;
          width: 40px;
          height: 40px;
          color: #ffffff;
          font-size: 14px;
          text-transform: uppercase;
          line-height: 38px;
          text-align: center;
          z-index: 100;
          cursor: pointer;
          border-radius: 50%;
          background: #222222;
          display: none;
          -webkit-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          transition: all 300ms ease;
        }

        .scroll-to-top:hover {
          color: #ffffff;
          background: #c18f59;
        }
        .featured-book {
          position: relative;
          padding: 120px 0px 50px;
          background: #555358;
          color: #ffffff;
        }

        .featured-book .image-box {
          text-align: center;
        }

        .featured-book .image-box img {
          display: inline-block;
          max-width: 40%;
        }

        .featured-book .info-column {
          text-align: right;
        }

        .featured-book .info-column .inner-box {
          margin-right: -30px;
          padding: 30px 0px;
        }

        .featured-book .detail-column .inner-box {
          margin-left: -30px;
          padding-top: 100px;
        }

        .featured-book .subtitle {
          position: relative;
          padding-bottom: 10px;
          margin-bottom: 20px;
          font-size: 16px;
          font-family: "Roboto Slab", serif;
          color: #aaaaaa;
        }

        .featured-book .subtitle:after {
          content: "";
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 50px;
          border-bottom: 1px solid #aaaaaa;
        }

        .featured-book .book-title {
          position: relative;
          margin-bottom: 5px;
          font-size: 30px;
          font-weight: 700;
          font-family: "Roboto Slab", serif;
          color: #ffffff;
        }

        .featured-book .written-by {
          position: relative;
          margin-bottom: 25px;
          font-style: italic;
          font-size: 15px;
          font-family: "Libre Baskerville", serif;
          color: #aaaaaa;
        }

        .featured-book .price {
          position: relative;
          font-size: 35px;
          font-weight: 700;
          font-family: "Roboto Slab", serif;
          color: #46c1d7;
        }

        .featured-book .book-category {
          position: relative;
          margin-bottom: 10px;
          font-size: 16px;
          font-family: "Roboto Slab", serif;
          color: #aaaaaa;
        }

        .featured-book .desc-text {
          position: relative;
          margin-bottom: 20px;
          font-size: 14px;
          color: #ffffff;
          font-weight: 500;
        }

        #battleBox {
          width: 350px; /* increasing width from 210 to 216 because your border takes 6 extra px*/
        }

        .tiny {
          width: 30%;
        }

        .rightRapper {
          margin: 0px; /* remove all margins to fit two divs in the container */
          display: inline-block; /* display block elements in one line */
        }

        .leftRapper {
          margin: 0px;
          display: inline-block;
        }
      `}</style>
    </React.Fragment>
  );
};

export default FeaturedBook;
